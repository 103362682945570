import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { i18n } from 'common/locale/i18n'
import constants from 'app/constants'
import shareService from 'social/services/share'
import Helmet from 'common/components/helmet'
import OnboardingTour from 'onboarding/components/OnboardingTour'
import { feedUrl, categoryListUrl } from 'urls'
import { useCurrentUser } from 'common/hooks'
import {
  fetchFeed,
  selectFeedMarkets,
  selectFeedMarketsError,
  selectFeedMarketsPagination,
  selectFeedMarketsStatus,
  selectMarketRepository,
  selectShouldLoadFeed,
} from 'store/markets'
import {
  fetchFeedBanners,
  selectFeedBanners,
  selectFeedBannersStatus,
  selectShouldLoadFeedBanners,
} from 'store/banners'
import { selectAbTestsStatus, selectActiveMarketType, selectActiveLanguage, selectPageLayout } from 'store/app'
import { useDispatch, useSelector } from 'react-redux'
import { addCampain, selectUserFromAllowedCountry } from 'store/account'
import { useLocation } from 'react-router-dom'
import { fromEntries } from 'common/util/fromEntries'
import { urlSearchParams } from 'common/util/urlSearchParams'
import CategoryNavigation from 'explore/components/category-navigation'

import './ViewFeed.scss'
import MarketListTemplate from 'questions/components/market-list-template'
import ErrorHandler from 'app/components/error-handler'
import futuurCover from 'landing/assets/futuur.com.png'
import QuickBet from 'questions/components/quick-bet'
import FAQs from 'views/FAQs'
import HotTopic from 'questions/components/hot-topic'
import useIsMobileSize from 'common/hooks/useIsMobileSize'
import {
  fetchCategories,
  fetchFeaturedCategories,
  fetchMainRootCategories,
  selectCategoriesByParentId,
  selectCategoryById,
  selectCategoryStatusById,
  selectMainRootCategoryList,
} from 'store/categories'
import { fetchTagsList, selectTagsList,  getTagName } from 'store/tags'

// Define the breakpoints for column count and the multiplier for items per column
const BREAKPOINTS = { default: 4, 1920: 4, 1440: 3, 1024: 2, 981: 1 }
const ITEMS_PER_COLUMN = 6

const ViewFeed = () => {
  const location = useLocation()
  const [queryParams, setQueryParams] = useState(fromEntries(new urlSearchParams(location.search)))
  const [columnCount, setColumnCount] = useState(BREAKPOINTS.default)
  const [selectedTag, setSelectedTag] = useState(null)

  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const activeMarketType = useSelector(selectActiveMarketType)
  const markets = useSelector(selectMarketRepository)
  const abTestsStatus = useSelector(selectAbTestsStatus)
  const pageLayout = useSelector(selectPageLayout)
  const feedIds = useSelector(selectFeedMarkets)
  const feedPagination = useSelector(selectFeedMarketsPagination)
  const error = useSelector(selectFeedMarketsError)
  const feedStatus = useSelector(selectFeedMarketsStatus)
  const banners = useSelector(selectFeedBanners)
  const bannersLoading = useSelector(selectFeedBannersStatus)
  const shouldLoadBanners = useSelector(selectShouldLoadFeedBanners(activeMarketType))
  const fromAllowedCountry = useSelector(selectUserFromAllowedCountry())
  const [outcomeSelected, setOutcomeSelected] = useState(null)
  const [position, setPosition] = useState(null)
  const [marketSelected, setMarketSelected] = useState(null)
  const isMobileSize = useIsMobileSize()
  const mainCategories = useSelector(selectMainRootCategoryList())
  const activeLanguage = useSelector(selectActiveLanguage())
  // Get tag data if we have a selected tag

  const [categorySettings, setCategorySettings] = useState()
  const [categoryId, setCategoryId] = useState()
  const shouldLoadFeed = useSelector(selectShouldLoadFeed(activeMarketType, location.search, categoryId))
  const categoryStatus = useSelector(selectCategoryStatusById(categoryId, activeMarketType))
  const currentSubcategory = useSelector(selectCategoryById(categoryId, activeMarketType))
  const subcategories = useSelector(selectCategoriesByParentId(categoryId, activeMarketType))
  const tagList = useSelector(selectTagsList())

  function parseTagUrl(url) {
    // Remove leading and trailing slashes
    let cleanedUrl = url.replace(/^\/|\/$/g, '')

    // If the URL is empty, return null
    if (cleanedUrl === '') {
      return null
    }

    // Split the URL into parts
    let parts = cleanedUrl.split('/')

    // Ensure it starts with "tags"
    if (parts[0] !== 'tags') {
      return null
    }

    // Remove "tags" from the array
    parts.shift()

    // If we have at least a tag slug
    if (parts && parts.length > 0) {
      const tagSlug = parts[0]
      const tagId = parts.length > 1 ? parts[1] : null

      return {
        tagSlug,
        tagId,
      }
    }

    return null
  }

  function parseMarketUrl(url) {
    // Remove leading and trailing slashes
    let cleanedUrl = url.replace(/^\/|\/$/g, '')

    // If the URL is empty, return null
    if (cleanedUrl === '') {
      return null
    }

    if (cleanedUrl === 'markets')
      return {
        category: null, // First category if exists, otherwise null
        fullTree: '/markets', // Full path, even for one category
        currentCategoryId: 1, // Last segment if it's a number
      }

    // Split the URL into parts
    let parts = cleanedUrl.split('/')

    // Ensure it starts with "markets"
    if (parts[0] !== 'markets') {
      return null
    }

    // Remove "markets" from the array
    parts.shift()

    // If the last segment is a number, treat it as categoryId
    let currentCategoryId = isNaN(parts[parts.length - 1]) ? null : parts.pop()
    let fullTree = parts.join('/')

    return {
      category: parts[0] || null, // First category if exists, otherwise null
      fullTree: fullTree || null, // Full path, even for one category
      currentCategoryId: currentCategoryId, // Last segment if it's a number
    }
  }

  useEffect(() => {
    if (shouldLoadBanners && !pageLayout.appLoading) {
      dispatch(fetchFeedBanners(activeMarketType))
    }
  }, [pageLayout.appLoading, activeMarketType])

  useEffect(() => {
    setQueryParams(fromEntries(new urlSearchParams(location.search)))
  }, [location.search])

  useEffect(() => {
    if (location.pathname.indexOf('/tags') > -1) {
      dispatch(fetchTagsList())

      // Parse tag from URL
      const tagSettings = parseTagUrl(location.pathname)

      if (tagSettings) {
        setSelectedTag(tagSettings.tagSlug)
        // Fetch tag data if needed
        if (tagSettings.tagSlug) {
          dispatch(getTagName(tagSettings.tagSlug))
        }
      } else {
        setSelectedTag(null)
      }

      return
    }

    // Reset selected tag if not on tags page
    setSelectedTag(null)
  }, [location.pathname])

  useEffect(() => {
    const categorySettings = parseMarketUrl(location.pathname)
    if (!categorySettings) setCategoryId(null)

    setCategorySettings(categorySettings)
    if (categorySettings && categorySettings.currentCategoryId) setCategoryId(categorySettings.currentCategoryId)
    else if (categorySettings && categorySettings.category)
      setCategoryId(mainCategories.find(c => c.slug === categorySettings.category)?.id)
  }, [location.pathname, mainCategories])

  useEffect(() => {
    if (pageLayout.appLoading || abTestsStatus !== constants.COMPLETE || categoryId === undefined) return

    // Calculate limit based on column count and items per column
    const limit = columnCount * ITEMS_PER_COLUMN

    const options = { limit, ...queryParams }
    if (categoryId) options.categories = categoryId
    if (selectedTag) options.tag = selectedTag

    if (shouldLoadFeed) {
      dispatch(fetchFeed(activeMarketType, options, location.search, shouldLoadFeed))
    }
  }, [pageLayout.appLoading, activeMarketType, queryParams, abTestsStatus, categoryId, selectedTag])

  useEffect(() => {
    if (location.pathname.includes('/manifest')) addCampain('manifest')
  }, [])

  useEffect(() => {
    if ((categoryId && categoryStatus === constants.IDLE) || categoryStatus === constants.COMPLETE) {
      dispatch(fetchCategories(categoryId, activeMarketType))
    }
  }, [activeMarketType, categoryId])

  useEffect(() => {
    if (pageLayout.appLoading || !mainCategories) return
    dispatch(fetchMainRootCategories(activeMarketType))
    dispatch(fetchFeaturedCategories(activeMarketType, { search: queryParams.search }))
  }, [pageLayout.appLoading, activeMarketType, queryParams.search])

  // Handle column count changes
  const handleBreakpointChange = newColumnCount => {
    if (newColumnCount + 1 !== columnCount) {
      setColumnCount(newColumnCount + 1)
    }
  }

  const renderHelmet = () => {
    // Default Feed Helmet (for / or /markets)
    if (location.pathname === '/' || location.pathname === '/markets') {
      const props = {
        title: i18n('landing.meta_tags.title'),
        description: i18n('landing.meta_tags.description', { marketCount: feedPagination.total }),
        images: futuurCover,
        url: shareService.shareableUrl(feedUrl()),
      }
      return <Helmet id="markets-helmet" {...props} />
    }

    // Category Helmet (for /markets/something)
    if (location.pathname.startsWith('/markets/') && categoryId && currentSubcategory) {
      let helmetProps = {
        title: 'Futuur - Category',
        description: i18n('global.general_description'),
        images: banners && banners.length ? banners[0].image : '',
        url: shareService.shareableUrl(feedUrl()),
      }

      if (currentSubcategory && currentSubcategory.seo) {
        const { seo } = currentSubcategory
        const language = activeLanguage === 'pt-br' ? 'pt_br' : 'en'
        helmetProps = {
          images: currentSubcategory.seo.seo_image || futuurCover,
          url: shareService.shareableUrl(categoryListUrl(currentSubcategory)),
          description: seo[`seo_description_${language}`],
          title: seo[`seo_title_${language}`] + ' Forecasts and Predictions | Futuur',
          alt: seo[`seo_alt_${language}`],
        }
      }

      return <Helmet id="category-helmet" {...helmetProps} />
    }

    // Tags Helmet (for /tags/something)
    if (location.pathname.startsWith('/tags/') && selectedTag) {
      return (
        <Helmet
          id="tags-helmet"
          title={i18n('tag.meta_tags.title', { tag: selectedTag })}
          description={i18n('tag.meta_tags.description', {
            tag: selectedTag,
            marketCount: (feedPagination && feedPagination.total) || 50,
          })}
        />
      )
    }

    // Default fallback
    const props = {
      title: i18n('landing.meta_tags.title'),
      description: i18n('landing.meta_tags.description', { marketCount: feedPagination.total }),
      images: futuurCover,
      url: shareService.shareableUrl(feedUrl()),
    }
    return <Helmet id="markets-helmet" {...props} />
  }
  /* we may use this for showing a custom category */
  // const renderCustomCategory = () => {
  //   return {}
  // }
  const renderMakeBet = _outcomeSelected => {
    const outcome = _outcomeSelected
    return (
      <QuickBet
        marketSelected={marketSelected}
        outcome={outcome}
        setOutcomeSelected={(outcome, position) => {
          setOutcomeSelected(outcome)
          if (position === 'yes' || position === 'no') {
            setPosition(position)
          }
        }}
        position={position}
        setMarketSelected={setMarketSelected}
        shouldShow={marketSelected && marketSelected.scoring_rule_metadata}
        place="Feed"
      />
    )
  }
  const classList = classnames('view-feed', {
    'view-feed--authenticated': Boolean(currentUser),
    'view-feed--loading': constants.LOADING,
  })
  return (
    <div className={classList}>
      <ErrorHandler location={location}>
        <CategoryNavigation
          title={i18n('markets.all_markets')}
          size="small"
          categories={mainCategories}
          subcategories={
            location.pathname.indexOf('/tags') > -1 ? tagList.map(t => ({ ...t, title: t.name })) : subcategories
          }
          fullPath={location.pathname.indexOf('/tags') > -1 ? '/tags' : categorySettings?.fullTree}
          currentSubcategory={categorySettings?.currentCategoryId && currentSubcategory}
        />
      </ErrorHandler>
      <ErrorHandler location={location}>
        <section className="view-feed__top">
          {!isMobileSize && !currentSubcategory && location.pathname === feedUrl() && (
            <div className="!px-4 md:!px-[30px] py-10">
              <HotTopic banners={banners} bannersLoading={bannersLoading} />
            </div>
          )}
        </section>
      </ErrorHandler>

      <ErrorHandler location={location} component="feed">
        {/*  look here for suspeese error */}

        <MarketListTemplate
          activeMarketType={activeMarketType}
          queryParams={queryParams}
          markets={feedIds.map(id => markets[id])}
          status={feedStatus}
          pagination={feedPagination}
          error={error}
          returnUrl={feedUrl()}
          onLoadMore={(activeMarketType, options) => {
            // Calculate limit based on column count and items per column for load more
            const limit = columnCount * ITEMS_PER_COLUMN
            dispatch(fetchFeed(activeMarketType, { ...options, ...queryParams, limit }))
          }}
          setOutcomeSelected={(outcome, position) => {
            setOutcomeSelected(outcome)
            if (position === 'yes' || position === 'no') {
              setPosition(position)
            }
          }}
          setMarketSelected={setMarketSelected}
          marketSelected={marketSelected}
          OutcomeSelected={outcomeSelected}
          page={location.pathname === feedUrl() ? 'feed' : ''}
          breakpoints={BREAKPOINTS}
          onBreakpointChange={handleBreakpointChange}
        />
        <FAQs />

        {currentUser &&
          currentUser.settings &&
          !currentUser.settings.seen_onboarding_app &&
          currentUser.username_confirmed && <OnboardingTour isFromAllowedCountry={fromAllowedCountry} />}
        {renderMakeBet(outcomeSelected)}
      </ErrorHandler>

      {renderHelmet()}
    </div>
  )
}

export default ViewFeed
