import React from 'react'
import { useSelector } from 'react-redux'
import { selectActiveLanguage } from 'store/app'
import { formatNumber } from 'common/util/localizeNumber'
import './currency.scss'
import { useCurrencies } from 'common/hooks'
import CurrencyIcon from 'common/components/currencyIcon'
// TODO: rename currency parameter to currencyId to keep consistency
function Currency({
  value,
  currency = 'OOM',
  mode,
  wrapSuffix = true,
  ratio,
  decimalPlaces,
  keepZeros = true,
  roundDown = false,
  showSuffix = true,
  showPrefix = true,
  color,
  showIcon = false,
  className,
  ...props
}) {
  const currencies = useCurrencies().currencies
  let locale = useSelector(selectActiveLanguage(props.locale))
  const currencyObject = currencies[currency]
  if (!currencyObject) return

  let currencyRatio = ratio || currencyObject.display_ratio
  let defaultDecimalPlaces = currencyObject.display_decimal_places || 2 // - Math.abs(Math.log10(currencyRatio))
  let maximumFractionDigits = decimalPlaces || defaultDecimalPlaces || 2
  let minimumFractionDigits = keepZeros ? maximumFractionDigits : 0
  let prefix = currencyObject.display_prefix
  let suffix = currencyObject.display_suffix
  if (!value) {
    value = 0
  }

  value /= currencyRatio

  if (mode === 'abbreviate') {
    maximumFractionDigits = 0
  }
  if (showSuffix && wrapSuffix) {
    suffix = <span>{suffix}</span>
  }

  let formattedValue = formatNumber(value, locale, minimumFractionDigits, maximumFractionDigits, roundDown)
  return (
    <div className={`currency__symbol ${className}`} style={{ color }}>
      {showPrefix && prefix}
      {showIcon && <CurrencyIcon currency={currencyObject} width="13px" />} {formattedValue}{' '}
      {showSuffix && suffix}
    </div>
  )
}

export default Currency
