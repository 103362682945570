import React, { useEffect, useRef, useState } from 'react'
import { Link, matchPath, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'

import { accountUrl } from 'profile/urls'
import { BellIcon, GlobeIcon, PortfolioIcon, RankingIcon } from 'common/components/icons'
import { CurrencySelector } from 'app/components/currency-selector'
import { activityUrl, cashInUrl, feedUrl, keepQueryString, leaderboardUrl, loginUrl, signupUrl } from 'urls'
import { i18n } from 'common/locale/i18n'
import { selectPageLayout, setNotificationWidgetOpen } from 'store/app'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveCurrency, useCurrencies, useCurrentUser, useScrollDirection } from 'common/hooks'
import { selectUserFromAllowedCountry } from 'store/account'

import classNames from 'classnames'
import NavbarMenu from 'app/components/navbar-menu'
import Button from 'common/ui/button'
import FutuurLogo from 'common/components/FutuurLogo'
import KycCallout from 'app/components/kyc-callout'

import './Navbar.scss'
import NotificationWidget from 'notifications/components/notification-widget'
import { selectUnreadNotificationCount } from 'store/notifications'
import useIsMobileSize from 'common/hooks/useIsMobileSize'
import SearchComponent from 'explore/components/search-component/SearchComponent'

const Navbar = props => {
  const currencies = useCurrencies().currencies
  const location = useLocation()
  const navigate = useNavigate()
  const navbarRef = useRef()
  const scrollDirection = useScrollDirection(0)
  const windowSize = useWindowSize()
  const isMobileSize = useIsMobileSize()

  const me = useCurrentUser()
  const dispatch = useDispatch()
  const layout = useSelector(selectPageLayout)
  const fromAllowedCountry = useSelector(selectUserFromAllowedCountry())
  const activeCurrency = useActiveCurrency()
  const undreadNotificationCount = useSelector(selectUnreadNotificationCount())

  const [notificationWidget, setNotificationWidget] = useState(false)
  const notificationPage = () => matchPath({ path: activityUrl() }, location.pathname)

  const onScroll = () => {
    if (isMobileSize) {
      if (scrollDirection === 'down') {
        document.body.classList.add('hide-navbar')
      } else {
        document.body.classList.remove('hide-navbar')
      }
    } else {
      document.body.classList.remove('hide-navbar')
    }
  }

  useEffect(() => {
    onScroll()
  }, [scrollDirection])

  useEffect(() => {
    dispatch(setNotificationWidgetOpen(notificationWidget))
  }, [notificationWidget])

  const renderLinkList = () => {
    return (
      <div className="navbar__link-list">
        <NavLink
          className={classNames('navbar__link', { 'navbar__link--active': isCurrentPage(feedUrl()) })}
          to={keepQueryString(feedUrl())}>
          <GlobeIcon width="18px" />

          <span className="navbar__link-text">
            <div>{i18n('navbar.markets')}</div>
          </span>
        </NavLink>

        {me && (
          <NavLink
            className={classNames('navbar__link', { 'navbar__link--active': isCurrentPage(accountUrl()) })}
            to={keepQueryString(accountUrl())}>
            <PortfolioIcon height="18px" />

            <span className="navbar__link-text">{i18n('navbar.portfolio')}</span>
          </NavLink>
        )}
        <NavLink
          className={classNames('navbar__link', { 'navbar__link--active': isCurrentPage(leaderboardUrl()) })}
          to={keepQueryString(leaderboardUrl())}>
          <RankingIcon height="18px" />

          <span className="navbar__link-text">{i18n('navbar.leaderboard')}</span>
        </NavLink>
      </div>
    )
  }

  const scrollToTop = e => {
    if (location.pathname === feedUrl()) {
      e.preventDefault()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const isCurrentPage = path => {
    // Extract the pathname from the current location
    const currentPath = location.pathname

    // Check if the current pathname matches any of the specified paths
    return path == currentPath
  }

  const renderKycCallout = () => {
    return <KycCallout me={me} />
  }

  const renderNavbarColumnLeft = () => {
    return (
      <Link to={feedUrl()} className="navbar__flex-column navbar__flex-column-left" onClick={scrollToTop}>
        {/* <FutuurIcon className="navbar__logo-mobile d-block d-xl-none" width="1.5em" fillcolor="gradient" /> */}
        <FutuurLogo className="navbar__logo d-block" width="100px" />
      </Link>
    )
  }

  const renderCurrencySelector = currency => {
    const _currency = me ? currency : currency && currency.id === 'OOM' ? currency : currencies.REAL_MONEY_PLACEHOLDER

    return (
      <div className="navbar__currency-selector min-w-[152px]">
        {_currency && <CurrencySelector currency={_currency} me={me} />}
      </div>
    )
  }

  const renderNavbarColumnRight = () => {
    return (
      <div className="navbar__flex-column navbar__flex-column--right gap-2 pr-0 w-full lg:!w-fit">
        {renderCurrencySelector(activeCurrency)}

        {me && me.id && fromAllowedCountry && !isMobileSize && (
          <Button
            onClick={() => navigate(cashInUrl())}
            className="navbar__button navbar__cash-in-button d-xl-flex"
            size="medium">
            {i18n('navbar.deposit')}
          </Button>
        )}
        {me && me.id && (
          <div
            className={classNames('navbar__notification', {
              'navbar__notification--active': notificationWidget || notificationPage(),
            })}>
            <i
              onClick={() => {
                isMobileSize ? navigate(activityUrl()) : setNotificationWidget(v => !v)
              }}
              className="navbar__notification--bell">
              <span className="navbar__notification--bell--cover"></span>
              <BellIcon width={20} />
            </i>
            {undreadNotificationCount > 0 && <i className="navbar__notification-warning" />}
            <NotificationWidget isOpen={notificationWidget} onClose={() => setNotificationWidget(false)} />
          </div>
        )}

        {(!me || !me.id) && (
          <div className="navbar__auth-button">
            <Button
              className="navbar__button d-none d-xl-block"
              size="medium"
              onClick={() => navigate(signupUrl())}
              type="outlined">
              {i18n('login.signup')}
            </Button>
            <Button
              className="navbar__button"
              size="medium"
              onClick={() => {
                localStorage.setItem('lastPath', location.pathname)
                navigate(loginUrl())
              }}
              variant="primary">
              {i18n('login.login')}
            </Button>
          </div>
        )}

        <NavbarMenu hasUserSession={me && me.id} me={me} />
      </div>
    )
  }
  const renderSearch = () => {
    return <SearchComponent />
  }

  const render = () => {
    const classList = classNames('navbar', {
      'navbar--guest': !me,
      'navbar--logged-in': me,
      'navbar--no-shadow': props.noBoxShadow,
      'navbar--no-fixed': props.noFixedBehaviour,
      'navbar--is-scrolling': window.scrollY > 0,
      'navbar--transparent': layout.transparentNavbar,
      'navbar--hide-infos': layout.hideNavbarInfos,
      'navbar--logo-only': layout.navbarLogoOnly,
      'd-none': layout.removeNavbar,
      overAddFuns: location.pathname === cashInUrl(),
    })
    const showKycCallout =
      me &&
      me.settings &&
      (me.kyc_status === 'requested' || me.kyc_status === 'refused') &&
      !me.settings.dismissed_kyc_callout
    return (
      <React.Fragment>
        <div className={classList} ref={navbarRef}>
          <div className={'navbar__container' + (location.pathname === cashInUrl() ? ' overAddFuns' : '')}>
            <div className="navbar__wrap">
              <div className="flex flex-row gap-10 items-center">
                {renderNavbarColumnLeft()}

                {renderLinkList()}

                {window.innerWidth > 960 && renderSearch()}

                {renderNavbarColumnRight()}
              </div>
              {props.children && <div className="navbar__attachment navbar--center">{props.children}</div>}
              {showKycCallout && renderKycCallout()}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  if (!layout.showNavigationBar && windowSize.width < 1024) {
    return null
  }

  return render()
}

export default Navbar
