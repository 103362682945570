import React, {useEffect, useState} from 'react'
import './SearchWidget.scss'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'

import {selectActiveCurrency, selectPageLayout} from 'store/app'
import constants from 'app/constants'
import LoadingIndicator from 'common/components/LoadingIndicator'
import {feedbackUrl, keepQueryString} from 'urls'
import {i18n} from 'common/locale/i18n'
import SearchForecastersResult from 'explore/components/search-forecasters-result'
import BlobIcon from 'common/components/icons/BlobIcon'
import Button from 'common/ui/button'
import TabbedContent from 'common/components/tabbed-content'

import {
  resetSearch,
  searchForecasters,
  searchMarkets,
  selectForecasters,
  selectForecastersPagination,
  selectForecastersStatus,
  selectMarkets,
  selectMarketsPagination,
  selectMarketsStatus,
} from 'store/search'
import SearchMarketsResult from 'explore/components/search-markets-result'
import Chip from 'common/ui/chip'

const SearchResults = ({
  text,
  onSearchForecasters = () => {},
  onSearchMarkets = () => {},
  onMarketClick = () => {},
  onForecasterClick = () => {},
  onSuggestMarketClick = () => {},
}) => {
  const markets = useSelector(selectMarkets())
  const forecasters = useSelector(selectForecasters())

  const marketsPagination = useSelector(selectMarketsPagination())
  const forecastersPagination = useSelector(selectForecastersPagination())

  const marketsStatus = useSelector(selectMarketsStatus())
  const forecastersStatus = useSelector(selectForecastersStatus())

  const marketsLoading = marketsStatus === constants.LOADING
  const forecastersLoading = forecastersStatus === constants.LOADING

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const isTextGreaterOrEqualToThreeChars = () => {
    return text !== '' && text !== null && text !== undefined && text.length >= 3
  }

  const renderMarkets = () => {
    return (
      <>
        {markets.length > 0 && (
          <SearchMarketsResult
            markets={markets}
            pagination={marketsPagination}
            status={marketsStatus}
            onLoadMore={onSearchMarkets}
            onMarketClick={onMarketClick}
          />
        )}

        {markets.length === 0 && renderNoResults(i18n('search.no_markets_found'))}
      </>
    )
  }

  const renderNoResults = (title, description = '', extraContent = '') => {
    return (
      <div className="flex flex-col items-center justify-content-center w-full !mt-4">
        <div className="w-16 h-16 flex items-center justify-content-center rounded-full bg-[var(--color-Neutral-2)] mb-6">
          <BlobIcon width="20px" />
        </div>
        <p className="text-center text-Neutral-6 font-bold text-xl leading-[133%] mb-2">{title}</p>
        <p
          className="text-Neutral-4 text-base leading-[150%] text-center"
          dangerouslySetInnerHTML={{ __html: description }}></p>
        {extraContent}
      </div>
    )
  }

  const renderForecasters = () => {
    return (
      <>
        {forecasters.length > 0 && (
          <SearchForecastersResult
            forecasters={forecasters}
            pagination={forecastersPagination}
            status={forecastersStatus}
            onLoadMore={onSearchForecasters}
            onForecasterClick={onForecasterClick}
          />
        )}

        {forecasters.length === 0 && renderNoResults(i18n('search.no_forecasters_found'))}
      </>
    )
  }

  const getTabList = () => {
    let tabs = []
    tabs.push({
      label: (
        <div className="flex justify-content-center items-center">
          {i18n('search.tabs.questions')}
          <Chip
            text={
              marketsPagination && marketsPagination.total > markets.length && markets.length > 0
                ? marketsPagination.total
                : markets.length
            }
            className="flex items-center justify-content-center h-6 px-2 bg-[--color-brand-01] text-[--color-brand-05] rounded-[500px] text-xs font-medium min-w-6 ml-2"
          />
        </div>
      ),
      render: renderMarkets,
    })
    tabs.push({
      label: (
        <div className="flex justify-content-center items-center">
          {i18n('search.tabs.users')}
          <Chip
            text={
              forecastersPagination && forecastersPagination.total > forecasters.length && forecasters.length > 0
                ? forecastersPagination.total
                : forecasters.length
            }
            className="flex items-center justify-content-center h-6 px-2 bg-[--color-brand-01] text-[--color-brand-05] rounded-[500px] text-xs font-medium min-w-6 ml-2"
          />
        </div>
      ),
      render: renderForecasters,
    })
    return tabs
  }

  const handleContentChange = newTabIndex => {
    setActiveTabIndex(newTabIndex)
  }

  return (
    <>
      {marketsLoading || forecastersLoading ? (
        <LoadingIndicator mode="list" />
      ) : (
        <>
          {/* Text length is less than three chars */}
          {!isTextGreaterOrEqualToThreeChars() && (
            <div className="search-results__empty">
              <p>{i18n('search.min_required_chars_message')} </p>
            </div>
          )}

          {/* No results found message */}
          {forecasters.length + markets.length <= 0 &&
            isTextGreaterOrEqualToThreeChars() &&
            renderNoResults(
              i18n('search.empty.title'),
              i18n('search.empty.description'),
              <Link to={keepQueryString(feedbackUrl('market-suggestion'))} onClick={onSuggestMarketClick}>
                <Button size="large" variant="primary" className="px-6 py-0 h-14 mt-10">
                  {i18n('feedback.form.topics.market_suggestion')}
                </Button>
              </Link>
            )}

          {forecasters.length + markets.length > 0 && isTextGreaterOrEqualToThreeChars() && (
            <TabbedContent
              contents={getTabList()}
              activeIndex={activeTabIndex}
              onContentChange={handleContentChange}
              className="w-full min-w-full max-w-full h-full min-h-full max-h-full"
            />
          )}
        </>
      )}
    </>
  )
}

const SearchWidget = ({
  text = null,
  isOpen = false,
  _ref,
  onMarketClick = () => {},
  onForecasterClick = () => {},
  onSuggestMarketClick = () => {},
}) => {
  const dispatch = useDispatch()
  const pageLayout = useSelector(selectPageLayout)
  let searchTime
  const activeCurrency = useSelector(selectActiveCurrency)

  useEffect(() => {
    if (pageLayout.appLoading) return

    clearTimeout(searchTime)

    searchTime = setTimeout(() => {
      if (text !== null && text !== '' && text !== undefined) {
        if (text.length >= 3) {
          handleSearch()
        } else {
          dispatch(resetSearch())
        }
      } else {
        dispatch(resetSearch())
      }
    }, 600)

    return () => {
      clearTimeout(searchTime)
    }
  }, [pageLayout.appLoading, text, activeCurrency])

  const onSearchMarkets = (options = { nextOffset: 0 }) => {
    const params = {
      offset: options.nextOffset,
      currencyId: activeCurrency,
    }

    dispatch(searchMarkets(text, params))
  }

  const onSearchForecasters = (options = { nextOffset: 0 }) => {
    const params = {
      offset: options.nextOffset,
      currencyId: activeCurrency,
    }

    dispatch(searchForecasters(text, params))
  }

  const handleSearch = () => {
    dispatch(resetSearch())
    onSearchMarkets()
    onSearchForecasters()
  }

  return (
    <div
      ref={_ref}
      style={isOpen ? {} : { display: 'none' }}
      className="min-w[532px] grid gap-8 justify-items-start bg-Neutral-1 w-full md:p-6 md:absolute md:left-0 md:top-[70px] md:shadow-[0px_8px_24px_0px_rgba(0,0,0,0.16)] md:border md:border-[var(--color-Neutral-3)] md:z-[200] md:rounded-2xl md:max-h-[366px] md:overflow-auto">
      {isOpen && (
        <SearchResults
          text={text}
          onSearchForecasters={onSearchForecasters}
          onSearchMarkets={onSearchMarkets}
          onMarketClick={onMarketClick}
          onForecasterClick={onForecasterClick}
          onSuggestMarketClick={onSuggestMarketClick}
        />
      )}
    </div>
  )
}

export { SearchWidget, SearchResults }
