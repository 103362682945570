import React, { useEffect, useRef, useState } from 'react'
import { SearchWidget } from 'app/components/Search-widget'
import SearchBox from 'explore/components/search-box/SearchBox'
import { marketsUrl } from 'urls'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  startMarketsLoading,
  startForecastersLoading,
  selectMarketsStatus,
  selectForecastersStatus,
} from 'store/search'
import constants from '../../../app/constants'

const SearchComponent = ({
  inputValue = '',
  neverHideSearchResultsWidget = false,
  onMarketClick = null,
  onForecasterClick = null,
  onSuggestMarketClick = null,
}) => {
  const [isResultsVisible, setIsResultsVisible] = useState(false)
  const [searchInput, setSearchInput] = useState(inputValue)
  const searchRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const marketsStatus = useSelector(selectMarketsStatus())
  const forecastersStatus = useSelector(selectForecastersStatus())

  useEffect(() => {
    const handleClickOutside = event => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsResultsVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const onMarketClickDefaultBehavior = () => {
    if (!neverHideSearchResultsWidget) {
      setIsResultsVisible(false)
    }
  }

  const onForecasterClickDefaultBehavior = () => {
    if (!neverHideSearchResultsWidget) {
      setIsResultsVisible(false)
    }
  }

  const onSuggestMarketClickDefaultBehavior = () => {
    if (!neverHideSearchResultsWidget) {
      setIsResultsVisible(false)
    }
  }

  return (
    <div className="navbar__search" ref={searchRef}>
      <SearchBox
        initialValue={searchInput}
        placeholder="Search here..."
        id="search__bar-input"
        onChange={value => {
          setSearchInput(value)

          if (
            value &&
            value.length >= 3 &&
            marketsStatus !== constants.LOADING &&
            forecastersStatus !== constants.LOADING
          ) {
            dispatch(startMarketsLoading())
            dispatch(startForecastersLoading())
          }

          if (value && value.length > 1) {
            setIsResultsVisible(true)
          } else {
            setIsResultsVisible(false)
          }
        }}
        onFocus={() => {
          if (searchInput && searchInput.length > 1) {
            setIsResultsVisible(true)
          }
        }}
        onEnter={() => {
          
          navigate(marketsUrl() + '?' + new URLSearchParams({ search: searchInput }))

          setIsResultsVisible(false)
        }}
      />
      <SearchWidget
        text={searchInput}
        isOpen={isResultsVisible || neverHideSearchResultsWidget}
        onMarketClick={onMarketClick ? onMarketClick : onMarketClickDefaultBehavior}
        onForecasterClick={onForecasterClick ? onForecasterClick : onForecasterClickDefaultBehavior}
        onSuggestMarketClick={onSuggestMarketClick ? onSuggestMarketClick : onSuggestMarketClickDefaultBehavior}
      />
    </div>
  )
}

export default SearchComponent
