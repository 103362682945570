import React, {lazy, Suspense, useEffect, useState} from 'react'
import classNames from 'classnames'

import constants from 'app/constants'
import {fetchRelatedMarketById, selectMarketById, selectRelatedMarketById} from 'store/markets'
import {i18n} from 'common/locale/i18n'
import {useActiveCurrency, useCurrencies, useCurrentUser} from 'common/hooks'
import {useDispatch, useSelector} from 'react-redux'
import {
  clearLastBet,
  fetchQuestionBetHistory,
  resetBetHistory,
  selectBetHistory,
  selectBetHistoryPagination,
  selectBetHistoryStatus,
} from 'store/wagers'
import './Market.scss' 
import MarketCard from '../market-card/MarketCard'

const QuestionChart = lazy(() => import('questions/components/question-chart'))
const QuestionSummary = lazy(() => import('questions/components/question-summary'))
const CommentPanel = lazy(() => import('comments/components/CommentPanel'))
const BetHistory = lazy(() => import('prediction/components/bet-history'))
const CompactQustion = lazy(() => import('../compact-question'))

// Skeleton loaders for lazy-loaded components
const ChartSkeleton = () => (
  <div className="animate-pulse p-4 rounded-lg bg-[var(--color-surface-1)]">
    <div className="h-40 bg-[var(--color-Neutral-2)] rounded w-full"></div>
  </div>
);

const SummarySkeleton = () => (
  <div className="animate-pulse p-4 rounded-lg bg-[var(--color-surface-1)]">
    <div className="h-4 bg-[var(--color-Neutral-2)] rounded w-full mb-2"></div>
    <div className="h-4 bg-[var(--color-Neutral-2)] rounded w-5/6 mb-2"></div>
    <div className="h-4 bg-[var(--color-Neutral-2)] rounded w-4/6"></div>
  </div>
);

const CommentSkeleton = () => (
  <div className="animate-pulse p-4 rounded-lg bg-[var(--color-surface-1)]">
    <div className="h-10 bg-[var(--color-Neutral-2)] rounded w-full mb-4"></div>
    <div className="flex items-start mb-4">
      <div className="h-10 w-10 bg-[var(--color-Neutral-2)] rounded-full mr-3"></div>
      <div className="flex-1">
        <div className="h-4 bg-[var(--color-Neutral-2)] rounded w-1/4 mb-2"></div>
        <div className="h-4 bg-[var(--color-Neutral-2)] rounded w-full"></div>
      </div>
    </div>
    <div className="flex items-start">
      <div className="h-10 w-10 bg-[var(--color-Neutral-2)] rounded-full mr-3"></div>
      <div className="flex-1">
        <div className="h-4 bg-[var(--color-Neutral-2)] rounded w-1/4 mb-2"></div>
        <div className="h-4 bg-[var(--color-Neutral-2)] rounded w-full"></div>
      </div>
    </div>
  </div>
);

const BetHistorySkeleton = () => (
  <div className="animate-pulse p-4 rounded-lg bg-[var(--color-surface-1)]">
    <div className="flex justify-between items-center mb-4">
      <div className="h-6 bg-[var(--color-Neutral-2)] rounded w-1/4"></div>
      <div className="h-6 bg-[var(--color-Neutral-2)] rounded w-1/6"></div>
    </div>
    {[1, 2, 3].map(i => (
      <div key={i} className="flex justify-between items-center mb-3 p-2 border border-[var(--color-Neutral-2)] rounded">
        <div className="h-5 bg-[var(--color-Neutral-2)] rounded w-1/3"></div>
        <div className="h-5 bg-[var(--color-Neutral-2)] rounded w-1/4"></div>
      </div>
    ))}
  </div>
);

const CompactQuestionSkeleton = () => (
  <div className="animate-pulse p-3 rounded-lg bg-[var(--color-surface-1)] mb-3">
    <div className="h-5 bg-[var(--color-Neutral-2)] rounded w-3/4 mb-2"></div>
    <div className="flex justify-between items-center">
      <div className="h-4 bg-[var(--color-Neutral-2)] rounded w-1/2"></div>
      <div className="h-4 bg-[var(--color-Neutral-2)] rounded w-1/6"></div>
    </div>
  </div>
);

// Preload components function
const preloadComponents = () => {
  // Preload all lazy-loaded components
  import('questions/components/question-chart');
  import('questions/components/question-summary');
  import('comments/components/CommentPanel');
  import('prediction/components/bet-history');
  import('questions/components/compact-question');
};

const Market = props => {
  const currencies = useCurrencies().currencies
  const {
    categories,
    currentOutcomeSelection,
    inlineBehavior,
    isModalComponent,
    showChart,
    showComments,
    showLatestBets,
    showMakeBet,
    showSummary,
    showRelatedMarket,
    className = '',
    isSelectedToBet = false,
    inMarketPage = false,
    showSelectedOutcome,
    isRelatedMarket,
    onOutcomeSelected,
    ref,
  } = props
  const dispatch = useDispatch()
  const questionId = (props.question && props.question.id) || props.questionId
  const question = props.question || useSelector(selectMarketById(questionId))
  const latestBets = useSelector(selectBetHistory(questionId))
  const latestBetsStatus = useSelector(selectBetHistoryStatus(questionId))
  const latestBetsPagination = useSelector(selectBetHistoryPagination(questionId))

  const activeCurrency = useActiveCurrency()
  const currentUser = useCurrentUser()

  const relatedMarkets = useSelector(selectRelatedMarketById())

  const [betHistoryFilters, setBetHistoryFilters] = useState({})

  const isRealMoney = activeCurrency && activeCurrency?.id !== currencies.OOM.id
  const availableMarket = (question && !isRealMoney) || (isRealMoney && question.real_currency_available)

  // Preload components when the market is loaded
  useEffect(() => {
    if (question) {
      preloadComponents();
    }
  }, [question]);

  // When bet result is hidden, clear showMakeBet state
  useEffect(() => {
    if (showMakeBet) {
      dispatch(clearLastBet())
    }
  }, [showMakeBet])

  useEffect(() => {
    if (betHistoryFilters && Object.keys(betHistoryFilters).length) loadNextLatestBets(true, true)
  }, [betHistoryFilters])
  useEffect(() => {
    if (showLatestBets) {
      dispatch(resetBetHistory({ questionId }))
      loadNextLatestBets()
    }
  }, [showLatestBets, questionId])

  // A complete version of a given market should be loaded
  useEffect(() => {
    if (showMakeBet || showSummary) {
      if (!questionId) {
        throw new Error("question or questionId parameter weren't provided to <Question> component")
      }
      dispatch(fetchRelatedMarketById(questionId))
    }
  }, [questionId])

  // TODO: Latest bets / bet history

  const loadNextLatestBets = (noOffset = false, clearOldBets = false) => {
    const requestParams = {
      question: question.id,
      currency_mode: betHistoryFilters.currency_mode || '',
      search: betHistoryFilters.search || '',
      startIndex: latestBetsPagination && !noOffset ? latestBetsPagination.offset + 5 : 0,
      limit: 5,
    }

    if (betHistoryFilters.following) requestParams.following = 1
    if (betHistoryFilters.my_bets) requestParams.my_bets = 1
    if (clearOldBets) dispatch(resetBetHistory({ questionId: question.id }))
    dispatch(fetchQuestionBetHistory(question, requestParams, clearOldBets))
  }

  const renderQuestionSummary = question => {
    if (question.description)
      return (
        <div className="question-card__section question-card__section--with-divider question-card__summary justify-content-center">
          <div>
            <Suspense fallback={<SummarySkeleton />}>
              <QuestionSummary question={question} />
            </Suspense>
          </div>
        </div>
      )
  }

  const renderQuestionChart = () => {
    return (
      <div
        style={{ paddingRight: '0' }}
        className="question-card__section question-card__chart question-card__section--gap-bottom justify-content-center">
        <Suspense fallback={<ChartSkeleton />}>
          <QuestionChart question={question} />
        </Suspense>
      </div>
    )
  }

  const renderLatestBets = () => {
    const classList = classNames('question-card__section', 'question-card__latest-bets')
    return (
      <div className={`relative z-[2] ${classList}`}>
        <h4 className="question-card__title">
          <span>{i18n('question_card.bet_history')}</span>
          <div className="question-card__tabed-content--count">{latestBetsPagination?.total || 0}</div>
        </h4>
        <Suspense fallback={<BetHistorySkeleton />}>
          <BetHistory
            bets={latestBets}
            type="question"
            me={currentUser}
            loading={latestBetsStatus === constants.LOADING && latestBets.length === 0}
            loadingMore={latestBetsStatus === constants.LOADING}
            filters={betHistoryFilters}
            latestBetsPagination={latestBetsPagination}
            onChangeFilter={(updateFilters) => setBetHistoryFilters(updateFilters)}
            onLoadMore={() => {
              loadNextLatestBets()
            }}
          />
        </Suspense>
      </div>
    )
  }

  const renderComments = () => (
    <Suspense fallback={<CommentSkeleton />}>
      <CommentPanel marketId={question.id} />
    </Suspense>
  )

  const renderRelatedMarkets = () => {
    if (!relatedMarkets || relatedMarkets.length <= 0) return ''
    return (
      <div className="question-card__related-markets question-card__section">
        <h4 className="question-card__title">{i18n('question_card.related_market')}</h4>
        {relatedMarkets.map(q => {
          return (
            <Suspense key={q.id} fallback={<CompactQuestionSkeleton />}>
              <CompactQustion question={q} />
            </Suspense>
          )
        })}
      </div>
    )
  }

  const render = () => {
    if (question == null) {
      return null
    }
    const betList = props.betList || question.wagers || []

    // const showMakeBet = shouldRenderMakeBet(betList)
    const showBetStatus = props.showBetStatus && betList.length && betList.length > 0
    const classList = classNames('question-card', className, {
      'question-card--show-bet-status': showBetStatus,
      'question-card--is-modal': isModalComponent,
      'question-card--is-selected': isSelectedToBet,
    })

    return (
      <article className={classList} ref={ref}>
        <MarketCard
          id={'market-card-' + question.id}
          question={question}
          categories={categories}
          currentOutcomeSelection={currentOutcomeSelection}
          showSummary={showSummary}
          inlineBehavior={inlineBehavior}
          className={className}
          showSelectedOutcome={showSelectedOutcome}
          isRelatedMarket={isRelatedMarket}
          onOutcomeSelected={onOutcomeSelected}
          betList={props.betlist}
          inMarketPage={inMarketPage}
          loadNextLatestBets={loadNextLatestBets}
          showBetStatus={showBetStatus}
        />

        {showSummary && renderQuestionSummary(question)}
        {showChart && availableMarket && renderQuestionChart()}
        {showComments && renderComments()}
        {showLatestBets && question.status !== 'x' && renderLatestBets()}

        {showRelatedMarket && renderRelatedMarkets()}
      </article>
    )
  }

  return render()
}

export default React.memo(Market)
