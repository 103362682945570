import React from 'react'
import SearchForecastersResultItem from "explore/components/search-forecasters-result-item";
import {InfiniteList} from "common/components/infinite-list";
import constants from "app/constants";

const SearchForecastersResult = ({
                            forecasters = [] ,
                            pagination,
                            status,
                            onLoadMore = () => {},
                            onForecasterClick = () => {},
}) => {

  return (
    <InfiniteList
      onLoadMore={pagination && pagination.next ? onLoadMore : () => {}}
      hideShowMoreButton={!pagination || !pagination.next}
      offset={pagination.offset}
      nextOffset={pagination.offset + 20}
      isLoading={status === constants.LOADING}>
      <div className="category-results flex flex-col h-[69vh] md:h-full !overflow-auto scrollbar-none">
        {
          forecasters.map((forecaster) => <SearchForecastersResultItem key={"user_"+forecaster.id} user={forecaster} onForecasterClick={onForecasterClick}/>)
        }
      </div>
    </InfiniteList>
  )
}

export default SearchForecastersResult
