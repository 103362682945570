import React, { useEffect, useRef, useState } from 'react'
import Masonry from 'react-masonry-css'
import classNames from 'classnames'
import LoadingIndicator from './LoadingIndicator'
import Button from 'common/ui/button'
import { useScrollPositionAtBottom } from 'common/hooks'
import LazyLoad from 'react-lazyload'
import './InfiniteGrid.scss'
import { i18n } from 'common/locale/i18n'
import { useAutoAnimate } from '@formkit/auto-animate/react'

const InfiniteList = props => {
  const {
    className,
    columnClassName,
    containerClassName = '',
    error,
    isLoading,
    loadedRows,
    onLoadMore,
    onRowRender,
    placeholder,
    style,
    totalRows,
    autoLoading = true,
    useMasonry = false,
    useTable = false,
    skeletonRows = 0,
    onRowSkeletonRender,
    useLazyLoad = false,
    renderTableHeader = null,
    breakpoints = { default: 1 },
    onBreakpointChange = null,
  } = props

  const container = useRef()
  const isScrollAtBottom = useScrollPositionAtBottom(container)
  const [currentBreakpoint, setCurrentBreakpoint] = useState(null)

  const [animationParent] = useAutoAnimate()

  useEffect(() => {
    if (autoLoading && isScrollAtBottom && !isLoading && (!totalRows || loadedRows < totalRows)) {
      onLoadMore({ startIndex: loadedRows })
    }
  }, [isScrollAtBottom])

  // Determine current breakpoint based on window width
  useEffect(() => {
    if (!onBreakpointChange) return

    const handleResize = () => {
      if (typeof window === 'undefined') return

      const width = window.innerWidth
      let newBreakpoint = 'default'

      // Find the appropriate breakpoint based on window width
      const breakpointKeys = Object.keys(breakpoints)
        .filter(key => key !== 'default')
        .map(Number)
        .sort((a, b) => b - a) // Sort in descending order

      for (const bp of breakpointKeys) {
        if (width >= bp) {
          newBreakpoint = bp
          break
        }
      }

      if (newBreakpoint !== currentBreakpoint) {
        setCurrentBreakpoint(newBreakpoint)
        onBreakpointChange(breakpoints[newBreakpoint] || breakpoints.default)
      }
    }

    handleResize() // Initial check
    window.addEventListener('resize', handleResize)
    
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [breakpoints, onBreakpointChange])

  const renderMasonryContent = () => {
    const content = []
    for (let index = 0; index < loadedRows; index++) {
      content.push(
        <LazyLoad key={`item-lazyload-${index}`} offset={350} height={350}>
          {onRowRender({ index })}
        </LazyLoad>
      )
    }
    return (
      <Masonry
        breakpointCols={breakpoints}
        className="infinite-grid__content"
        columnClassName={classNames('infinite-grid__column', columnClassName)}>
        {content}
      </Masonry>
    )
  }

  const renderListContent = () => {
    const content = []
    for (let index = 0; index < loadedRows; index++) {
      if (useLazyLoad)
        content.push(
          <LazyLoad overflow key={'lazyloaded-notification' + index}>
            {onRowRender({ index })}
          </LazyLoad>
        )
      else content.push(onRowRender({ index }))
    }
    return (
      <div ref={animationParent} className="infinite-grid__results">
        {content}
      </div>
    )
  }
  const renderTableContent = () => {
    const content = []
    for (let index = 0; index < loadedRows; index++) {
      if (useLazyLoad)
        content.push(
          <LazyLoad overflow key={'lazyloaded-notification' + index}>
            {onRowRender({ index })}
          </LazyLoad>
        )
      else content.push(onRowRender({ index }))
    }
    return (
      <table ref={animationParent} className={classNames('infinite-table', columnClassName)}>
        {renderTableHeader}
        <tbody> {content}</tbody>
      </table>
    )
  }

  const renderFooter = () => {
    if (isLoading && skeletonRows > 0 && onRowSkeletonRender) {
      return [...Array(skeletonRows).keys()].map(onRowSkeletonRender)
    }
    if (isLoading && autoLoading) {
      return <LoadingIndicator className="infinite-grid__loading" mode="list" />
    }

    if (error) {
      return (
        <div className="infinite-grid__footer-container">
          <Button className="infinite-grid__button" size="large" type="outlined" onClick={onLoadMore}>
            {i18n('infinite_grid.retry')}
          </Button>
        </div>
      )
    }

    if (isLoading && totalRows === 0) {
      return <LoadingIndicator className="infinite-grid__loading" mode="list" />
    }

    if (placeholder && totalRows === 0) {
      return <div className="infinite-grid__placeholder">{placeholder}</div>
    }

    if (!autoLoading && loadedRows < totalRows) {
      return (
        <div className="infinite-grid__footer-container">
          <Button
            loading={isLoading}
            className="infinite-grid__button"
            size="large"
            variant="white"
            disabled={loadedRows >= totalRows}
            onClick={() => onLoadMore({ startIndex: loadedRows })}>
            {i18n('infinite_grid.load_more')}
          </Button>
        </div>
      )
    }

    return null
  }

  return (
    <div ref={container} className={classNames('infinite-grid', className)} style={style}>
      <div className={classNames('infinite-grid__content', containerClassName)}>
        {useMasonry ? renderMasonryContent() : useTable ? renderTableContent() : renderListContent()}
      </div>
      <div className="infinite-grid__footer">{renderFooter()}</div>
    </div>
  )
}

export default InfiniteList
